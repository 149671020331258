<script setup>
import Checkmark from '@/Components/Input/Checkmark.vue';
import { Link, useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';

import Footer from '@/Components/Footer/FooterLogin.vue';
import MailInput from '@/Pages/Auth/Partials/MailInput.vue';
import PhoneInput from '@/Pages/Auth/Partials/PhoneInput.vue';

let route = inject('route');

const props = defineProps({
    query: Object,
});

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    email_verification_code: '',
    phone_number: '',
    phone_number_verification_code: '',
    password: '',
    password_confirmation: '',
    terms: false,
    ...props.query,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        terms: form.terms === true ? 'on' : '',
    })).post(route('register'), {
        onFinish: () => form.reset('password'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout title="Registreer">
        <div class="container relative -top-[200px] flex w-full flex-col items-center">
            <div class="w-full max-w-[550px] rounded-2xl bg-white p-8 text-blue">
                <h1 class="mb-2 text-8 font-bold">
                    {{ $t('Register') }}
                </h1>

                <div class="mb-12 text-base font-medium">
                    {{ $t('Enter your credentials to set up your Flexable account.') }}
                </div>

                <FormKit
                    v-model="form.first_name"
                    type="text"
                    name="first_name"
                    :label="$t('First name')"
                    :errors="form.errors.first_name"
                    :value="form.first_name"
                />

                <FormKit
                    v-model="form.last_name"
                    type="text"
                    name="last_name"
                    :label="$t('Last name')"
                    :errors="form.errors.last_name"
                    :value="form.last_name"
                />

                <div class="mb-4">
                    <MailInput :form="form" />
                </div>
                <div class="mb-4">
                    <PhoneInput :form="form" />
                </div>

                <FormKit
                    v-model="form.password"
                    type="password"
                    name="password"
                    :label="$t('New {attribute}', { attribute: 'password' })"
                    :errors="form.errors.password"
                    :value="form.password"
                />
                <FormKit
                    v-model="form.password_confirmation"
                    type="password"
                    name="password_confirmation"
                    :label="$t('Repeat {attribute}', { attribute: 'password' })"
                    :errors="form.errors.password_confirmation"
                    :value="form.password_confirmation"
                />

                <Checkmark
                    v-model:value="form.terms"
                    :error="form.errors.terms"
                >
                    <div
                        class="leading-tight"
                        v-html="
                            $t('I agree to the {terms_of_service} and {privacy_policy}', {
                                terms_of_service: `<a class='&quot;font-bold' underline&quot; target='&quot;_blank&quot;' href='&quot;${route(
                                    'terms.employer'
                                )}&quot;'>${$t('Terms of Service')}</a>`,
                                privacy_policy: `<a class='&quot;font-bold' underline&quot; target='&quot;_blank&quot;' href='&quot;${route(
                                    'privacy'
                                )}&quot;'>${$t('Privacy Policy')}</a>`,
                            })
                        "
                    />
                </Checkmark>

                <Button
                    full-width
                    type="button"
                    :processing="form.processing"
                    :recently-successful="form.recentlySuccessful"
                    :text="$t('Register')"
                    @click="submit()"
                />

                <div class="mt-8 w-full text-center text-sm">
                    {{ $t('Already have an account?') }}
                    <Link
                        :href="route('login')"
                        class="font-bold"
                    >
                        {{ $t('Login') }}
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    </GuestLayout>
</template>
